// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { OneOnOneSessionModalCoachFragmentDoc } from '../../components/calendar/__generated-gql-types__/OneOnOneSessionModal.generated';
import { PackageDetailsFieldsUserFragmentDoc } from '../../components/pricing-packages/__generated-gql-types__/PackageDetailsFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthContextUserFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  pictureLink?: string | null;
  isOps?: boolean | null;
  opsPermissions?: Array<Types.OpsPermission> | null;
  createdAt: number;
  applicant?: { __typename: 'Applicant'; id: string } | null;
  impersonator?: { __typename: 'User'; id: string } | null;
  phoneNumber?: {
    __typename?: 'PhoneNumber';
    countryCode: string;
    callingCountryCode: string;
    nationalFormat: string;
  } | null;
  coach?: {
    __typename?: 'Coach';
    id: string;
    slug: string;
    status: Types.CoachStatus;
    visibility: Types.CoachVisibility;
    liveAt?: number | null;
    calendarLink?: string | null;
    allowPaidClass?: boolean | null;
    newExperience?: boolean | null;
    tier: Types.SkuTier;
    hourlyRate?: number | null;
    availability?: {
      __typename?: 'CoachAvailability';
      defaultAvailability?: {
        __typename?: 'DefaultAvailability';
        totalInMinute: number;
      } | null;
    } | null;
  } | null;
};

export type AuthContextQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AuthContextQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    isOps?: boolean | null;
    opsPermissions?: Array<Types.OpsPermission> | null;
    createdAt: number;
    applicant?: { __typename: 'Applicant'; id: string } | null;
    impersonator?: { __typename: 'User'; id: string } | null;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    coach?: {
      __typename?: 'Coach';
      id: string;
      slug: string;
      status: Types.CoachStatus;
      visibility: Types.CoachVisibility;
      liveAt?: number | null;
      calendarLink?: string | null;
      allowPaidClass?: boolean | null;
      newExperience?: boolean | null;
      tier: Types.SkuTier;
      hourlyRate?: number | null;
      availability?: {
        __typename?: 'CoachAvailability';
        defaultAvailability?: {
          __typename?: 'DefaultAvailability';
          totalInMinute: number;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logoutUrl: string };

export type RequestLoginVerificationCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type RequestLoginVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestLoginSecurityCode: boolean;
};

export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  code: Types.Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    isOps?: boolean | null;
    opsPermissions?: Array<Types.OpsPermission> | null;
    createdAt: number;
    applicant?: { __typename: 'Applicant'; id: string } | null;
    impersonator?: { __typename: 'User'; id: string } | null;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    coach?: {
      __typename?: 'Coach';
      id: string;
      slug: string;
      status: Types.CoachStatus;
      visibility: Types.CoachVisibility;
      liveAt?: number | null;
      calendarLink?: string | null;
      allowPaidClass?: boolean | null;
      newExperience?: boolean | null;
      tier: Types.SkuTier;
      hourlyRate?: number | null;
      availability?: {
        __typename?: 'CoachAvailability';
        defaultAvailability?: {
          __typename?: 'DefaultAvailability';
          totalInMinute: number;
        } | null;
      } | null;
    } | null;
  };
};

export type RequestSignupVerificationCodeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type RequestSignupVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestSignupSecurityCode: boolean;
};

export type SignupMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  code: Types.Scalars['String']['input'];
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    isOps?: boolean | null;
    opsPermissions?: Array<Types.OpsPermission> | null;
    createdAt: number;
    applicant?: { __typename: 'Applicant'; id: string } | null;
    impersonator?: { __typename: 'User'; id: string } | null;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    coach?: {
      __typename?: 'Coach';
      id: string;
      slug: string;
      status: Types.CoachStatus;
      visibility: Types.CoachVisibility;
      liveAt?: number | null;
      calendarLink?: string | null;
      allowPaidClass?: boolean | null;
      newExperience?: boolean | null;
      tier: Types.SkuTier;
      hourlyRate?: number | null;
      availability?: {
        __typename?: 'CoachAvailability';
        defaultAvailability?: {
          __typename?: 'DefaultAvailability';
          totalInMinute: number;
        } | null;
      } | null;
    } | null;
  };
};

export type SignupAsCoachMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SignupAsCoachMutation = {
  __typename?: 'Mutation';
  signupAsCoach: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    isOps?: boolean | null;
    opsPermissions?: Array<Types.OpsPermission> | null;
    createdAt: number;
    coach?: {
      __typename?: 'Coach';
      id: string;
      slug: string;
      status: Types.CoachStatus;
      visibility: Types.CoachVisibility;
      liveAt?: number | null;
      calendarLink?: string | null;
      allowPaidClass?: boolean | null;
      newExperience?: boolean | null;
      tier: Types.SkuTier;
      hourlyRate?: number | null;
      availability?: {
        __typename?: 'CoachAvailability';
        defaultAvailability?: {
          __typename?: 'DefaultAvailability';
          totalInMinute: number;
        } | null;
      } | null;
    } | null;
    applicant?: { __typename: 'Applicant'; id: string } | null;
    impersonator?: { __typename: 'User'; id: string } | null;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
  };
};

export const AuthContextUserFragmentDoc = gql`
  fragment AuthContext_User on User {
    id
    email
    firstName
    lastName
    pictureLink
    applicant {
      id
      __typename
    }
    impersonator {
      id
      __typename
    }
    isOps
    opsPermissions
    createdAt
    phoneNumber {
      countryCode
      callingCountryCode
      nationalFormat
    }
    coach {
      id
      slug
      status
      visibility
      liveAt
      calendarLink
      allowPaidClass
      newExperience
      tier
      availability {
        defaultAvailability {
          totalInMinute
        }
      }
      ...OneOnOneSessionModal_Coach
    }
    ...PackageDetailsFields_User
  }
  ${OneOnOneSessionModalCoachFragmentDoc}
  ${PackageDetailsFieldsUserFragmentDoc}
`;
export const AuthContextDocument = gql`
  query AuthContext {
    user {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;

/**
 * __useAuthContextQuery__
 *
 * To run a query within a React component, call `useAuthContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthContextQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options,
  );
}
export function useAuthContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options,
  );
}
export function useAuthContextSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthContextQuery,
    AuthContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthContextQuery, AuthContextQueryVariables>(
    AuthContextDocument,
    options,
  );
}
export type AuthContextQueryHookResult = ReturnType<typeof useAuthContextQuery>;
export type AuthContextLazyQueryHookResult = ReturnType<
  typeof useAuthContextLazyQuery
>;
export type AuthContextSuspenseQueryHookResult = ReturnType<
  typeof useAuthContextSuspenseQuery
>;
export type AuthContextQueryResult = Apollo.QueryResult<
  AuthContextQuery,
  AuthContextQueryVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logoutUrl
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const RequestLoginVerificationCodeDocument = gql`
  mutation RequestLoginVerificationCode($email: String!) {
    requestLoginSecurityCode(email: $email)
  }
`;
export type RequestLoginVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestLoginVerificationCodeMutation,
  RequestLoginVerificationCodeMutationVariables
>;

/**
 * __useRequestLoginVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestLoginVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLoginVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLoginVerificationCodeMutation, { data, loading, error }] = useRequestLoginVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestLoginVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestLoginVerificationCodeMutation,
    RequestLoginVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestLoginVerificationCodeMutation,
    RequestLoginVerificationCodeMutationVariables
  >(RequestLoginVerificationCodeDocument, options);
}
export type RequestLoginVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestLoginVerificationCodeMutation
>;
export type RequestLoginVerificationCodeMutationResult =
  Apollo.MutationResult<RequestLoginVerificationCodeMutation>;
export type RequestLoginVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestLoginVerificationCodeMutation,
    RequestLoginVerificationCodeMutationVariables
  >;
export const LoginDocument = gql`
  mutation Login($email: String!, $code: String!) {
    login(email: $email, code: $code) {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RequestSignupVerificationCodeDocument = gql`
  mutation RequestSignupVerificationCode($email: String!) {
    requestSignupSecurityCode(email: $email)
  }
`;
export type RequestSignupVerificationCodeMutationFn = Apollo.MutationFunction<
  RequestSignupVerificationCodeMutation,
  RequestSignupVerificationCodeMutationVariables
>;

/**
 * __useRequestSignupVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestSignupVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSignupVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSignupVerificationCodeMutation, { data, loading, error }] = useRequestSignupVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestSignupVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestSignupVerificationCodeMutation,
    RequestSignupVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestSignupVerificationCodeMutation,
    RequestSignupVerificationCodeMutationVariables
  >(RequestSignupVerificationCodeDocument, options);
}
export type RequestSignupVerificationCodeMutationHookResult = ReturnType<
  typeof useRequestSignupVerificationCodeMutation
>;
export type RequestSignupVerificationCodeMutationResult =
  Apollo.MutationResult<RequestSignupVerificationCodeMutation>;
export type RequestSignupVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    RequestSignupVerificationCodeMutation,
    RequestSignupVerificationCodeMutationVariables
  >;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $code: String!
    $firstName: String!
    $lastName: String!
  ) {
    signup(
      email: $email
      code: $code
      firstName: $firstName
      lastName: $lastName
      coach: {}
    ) {
      id
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    options,
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const SignupAsCoachDocument = gql`
  mutation SignupAsCoach {
    signupAsCoach(coach: {}) {
      id
      ...AuthContext_User
      coach {
        id
      }
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type SignupAsCoachMutationFn = Apollo.MutationFunction<
  SignupAsCoachMutation,
  SignupAsCoachMutationVariables
>;

/**
 * __useSignupAsCoachMutation__
 *
 * To run a mutation, you first call `useSignupAsCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupAsCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupAsCoachMutation, { data, loading, error }] = useSignupAsCoachMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignupAsCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupAsCoachMutation,
    SignupAsCoachMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignupAsCoachMutation,
    SignupAsCoachMutationVariables
  >(SignupAsCoachDocument, options);
}
export type SignupAsCoachMutationHookResult = ReturnType<
  typeof useSignupAsCoachMutation
>;
export type SignupAsCoachMutationResult =
  Apollo.MutationResult<SignupAsCoachMutation>;
export type SignupAsCoachMutationOptions = Apollo.BaseMutationOptions<
  SignupAsCoachMutation,
  SignupAsCoachMutationVariables
>;
