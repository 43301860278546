import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAuth } from './AuthContext';
import {
  useFeatureInteractionsContextFeatureInteractedLazyQuery,
  useFeatureInteractionsContextGetUserLazyQuery,
  useFeatureInteractionsContextLogInteractionMutation,
} from './__generated-gql-types__/FeatureInteractionsContext.generated';

export enum FeatureInteraction {
  EXAMPLE = '__example__',
  CLASS_ONBOARDING_INFO_MODAL = 'class-onboarding-info-modal',
  IM_LIVE_SOCIAL_SHARE_MODAL = 'im-live-social-share-modal',
  PHONE_NUMBER_MODAL = 'phone-number-modal',
  EVENTS_ONBOARDING_INFO_MODAL = 'events-onboarding-info-modal',
  SESSION_SUMMARY_MODAL = 'session-summary-modal',
  TRIAL_SESSION_ONBOARDING_MODAL = 'trial-session-onboarding-modal',
  COMPREHENSIVE_PACKAGE_CONVERSION = 'comprehensive-package-conversion',
}

interface FeatureInteractionsContext {
  isFeatureInteracted: (feature: FeatureInteraction) => boolean;
  fetchIsFeatureInteracted: (
    feature: FeatureInteraction,
    maxInitialInteraction?: number,
    maxPostCoolOffInteraction?: number,
    since?: number,
  ) => Promise<boolean>;
  logFeatureInteraction: (feature: FeatureInteraction) => Promise<boolean>;
}

const MISSING_FEATURE_INTERACTIONS_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <FeatureInteractionsProvider>';

const featureInteractionsContext = createContext<FeatureInteractionsContext>({
  get isFeatureInteracted(): never {
    throw new Error(MISSING_FEATURE_INTERACTIONS_CONTEXT_PROVIDER);
  },
  get fetchIsFeatureInteracted(): never {
    throw new Error(MISSING_FEATURE_INTERACTIONS_CONTEXT_PROVIDER);
  },
  get logFeatureInteraction(): never {
    throw new Error(MISSING_FEATURE_INTERACTIONS_CONTEXT_PROVIDER);
  },
});

export const useFeatureInteractions: () => FeatureInteractionsContext = () =>
  useContext<FeatureInteractionsContext>(featureInteractionsContext);

const FeatureInteractionsProvider: React.FC<
  React.PropsWithChildren<Record<never, never>>
> = ({ children }) => {
  const { currentUser, isImpersonating } = useAuth();
  const [hasLoadedFromServer, setHasLoadedFromServer] = useState(false);
  const [interactedFeatures, setInteractedFeatures] =
    useState<Nullable<Set<FeatureInteraction>>>(null);

  const [fetchFeatureInteractions] =
    useFeatureInteractionsContextGetUserLazyQuery();

  const [fetchIsFeatureInteracted] =
    useFeatureInteractionsContextFeatureInteractedLazyQuery();

  const [logFeatureInteraction] =
    useFeatureInteractionsContextLogInteractionMutation();

  useEffect(() => {
    if (currentUser) {
      let isCancelled = false;
      void (async () => {
        const { data } = await fetchFeatureInteractions();
        if (!isCancelled) {
          setInteractedFeatures(
            data?.user?.interactedFeatures
              ? new Set(
                  data.user
                    .interactedFeatures as unknown as FeatureInteraction[],
                )
              : null,
          );
          setHasLoadedFromServer(true);
        }
      })();

      return () => {
        isCancelled = true;
      };
    } else {
      setInteractedFeatures(null);
      setHasLoadedFromServer(false);
    }
  }, [currentUser, fetchFeatureInteractions]);

  const value: FeatureInteractionsContext = useMemo(
    () => ({
      isFeatureInteracted: (feature: FeatureInteraction) =>
        // default to true until loaded from server
        hasLoadedFromServer ? !!interactedFeatures?.has(feature) : true,
      fetchIsFeatureInteracted: async (
        feature: FeatureInteraction,
        maxInitialInteraction: number,
        maxPostCoolOffInteraction: number,
        since: number,
      ) => {
        const { data, error } = await fetchIsFeatureInteracted({
          variables: {
            feature,
            maxInitialInteraction,
            maxPostCoolOffInteraction,
            since,
          },
        });
        if (error) {
          console.warn(error);
        }
        return !!data?.featureInteracted;
      },
      logFeatureInteraction: async (feature: FeatureInteraction) => {
        if (isImpersonating) {
          return true;
        }

        const { data, errors } = await logFeatureInteraction({
          variables: { feature },
        });
        if (data?.logFeatureInteraction) {
          // if successfully logged, add to set
          // create new Set to trigger updates to memoized values
          setInteractedFeatures((prev) => new Set(prev).add(feature));
        } else if (errors) {
          console.warn('Error logging feature interaction', errors);
        }
        return !!data?.logFeatureInteraction;
      },
    }),
    [
      isImpersonating,
      interactedFeatures,
      fetchIsFeatureInteracted,
      logFeatureInteraction,
      hasLoadedFromServer,
    ],
  );

  return (
    <featureInteractionsContext.Provider value={value}>
      {children}
    </featureInteractionsContext.Provider>
  );
};

export default FeatureInteractionsProvider;
