import Cookies from 'js-cookie';

import { type Scalars } from '../__generated-gql-types__/globalTypes';

import { LELAND_DOMAIN } from './constants';

export const IMPERSONATED_USER_KEY = 'x-leland-impersonation';

interface ILelandImpersonation {
  /**
   * Note that this could not retrieve the impersonated user ID for SSR requests
   */
  getImpersonatedUserIdFromBrowserCookie: () => Nullable<
    Scalars['UserUrn']['output']
  >;
  setImpersonatedUserId: (userUrn: Scalars['UserUrn']['output']) => void;
  removeImpersonatedUserId: () => void;
}

// Use cookies for cross-subdomain support
export const LelandImpersonation: ILelandImpersonation = {
  getImpersonatedUserIdFromBrowserCookie: () =>
    Cookies.get(IMPERSONATED_USER_KEY) ?? null,
  setImpersonatedUserId: (userUrn) =>
    Cookies.set(IMPERSONATED_USER_KEY, userUrn, {
      domain: LELAND_DOMAIN,
    }),
  removeImpersonatedUserId: () =>
    Cookies.remove(IMPERSONATED_USER_KEY, {
      domain: LELAND_DOMAIN,
    }),
};
