import Rollbar from 'rollbar';

import { isServer } from './constants';

let rollbar: Rollbar | null = null;

const init = (): Rollbar => {
  rollbar = new Rollbar({
    enabled: process.env.NODE_ENV === 'production',
    accessToken: isServer
      ? process.env.ROLLBAR_SERVER_TOKEN
      : process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    payload: {
      environment: process.env.NEXT_PUBLIC_APPLICATION_ENV,
      javascript: {
        source_map_enabled: true,
        code_version: process.env.NEXT_PUBLIC_LELAND_VERSION,
      },
    },
    captureUncaught: true,
    captureUnhandledRejections: true,
  });

  return rollbar;
};

export { init, rollbar };
